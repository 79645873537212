import React, { useState, useEffect } from 'react';
import {
    CircularProgress,
    Grid,
    TextField,
    makeStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import AddressInput from '../common/addressinput';
import { useTranslation } from 'react-i18next';
import Address from '../domain/address';
import { cloneDeep } from 'lodash';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import { saveSurvey } from '../modules/surveydata';
import { useDispatch } from 'react-redux';
import SaveButton from '../common/savebutton';

const useStyles = makeStyles((theme) => ({
    backArrow: {
        cursor: 'pointer',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
}));

export default function SurveyEditView(props) {
    const dispatch = useDispatch();
    const [survey, setSurvey] = useState();
    const [saving, setSaving] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();

    const onCancel = () => {
        props.history.goBack();
    };

    const onSave = async () => {
        setSaving(true);
        const version = parseInt(survey.version, 10);
        const surveyNew = cloneDeep(survey);
        surveyNew.version = version + 1;
        var result = await saveSurvey(surveyNew)(dispatch);
        if (result) {
            setSurvey(surveyNew);
        }
        setSaving(false);
    };

    const onAddressChange = (address) => {
        const surveyNew = cloneDeep(survey);
        surveyNew.address = address;
        setSurvey(surveyNew);
    };

    const onCommonChange = (event) => {
        const surveyNew = cloneDeep(survey);
        const target = event.target;
        const value = target.value;
        const name = target.name;

        switch (name) {
            case 'comment':
                surveyNew.comment = value;
                break;
            case 'survey-usage':
                surveyNew.usage = value;
                break;
            case 'survey-classification':
                surveyNew.classification = value;
                break;
            default:
                break;
        }

        setSurvey(surveyNew);
    };

    const onMachineChange = (event, roomIndex, machineIndex) => {
        const surveyNew = cloneDeep(survey);
        const target = event.target;
        const value = target.value;
        const name = target.name;

        console.log(name + ', ' + roomIndex + ', ' + machineIndex + ', ' + value);
        switch (name) {
            case 'machine-room-name':
                surveyNew.machineRooms[roomIndex].name = value;
                break;
            case 'machine-name':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].name = value;
                break;
            case 'air-volume':
                const newValue = value.toString().replace(',', '.');
                if (!/^\d*[.]?\d{0,2}$/.test(newValue)) {
                    return;
                } else if (newValue >= 0) {
                    surveyNew.machineRooms[roomIndex].machines[machineIndex].airVolume = newValue;
                }
                break;
            case 'machine-service-area':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].serviceArea = value;
                break;
            case 'machine-filter-class':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filterClass = value;
                break;
            case 'filter-efficiency':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filterEfficiency = value;
                break;
            case 'machine-filter-type':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filterType = value;
                break;
            case 'machine-heat-recovery-mode':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].heatRecoveryModel = value;
                break;
            default:
                break;
        }

        setSurvey(surveyNew);
    };

    const onFilterChange = (event, roomIndex, machineIndex, filterIndex) => {
        const surveyNew = cloneDeep(survey);
        const target = event.target;
        const value = target.value;
        const name = target.name;

        switch (name) {
            case 'filter-height':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filters[filterIndex].dimensionHeight = value;
                break;
            case 'filter-width':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filters[filterIndex].dimensionWidth = value;
                break;
            case 'filter-maxdepth':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filters[filterIndex].maxDepth = value;
                break;
            case 'filter-count':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filters[filterIndex].count = value;
                break;
            default:
                break;
        }

        setSurvey(surveyNew);
    };

    useEffect(() => {
        let surveyMod = props.survey;
        //temppu jotta voidaan käyttää address inputtia, väliaikinen ratkaisu
        surveyMod.address = new Address(props.survey.address);
        setSurvey(surveyMod);
        console.log(surveyMod);
    }, [props.survey]);

    if (!survey) {
        return <CircularProgress color={'secondary'} size={'1rem'} />;
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <h2>
                    <ArrowBackIos onClick={onCancel} className={classes.backArrow} name="close" />
                    <span data-testid="survey-name">
                        {survey.name} - <em>{t('general.editing')}</em>
                    </span>
                </h2>
            </Grid>
            <Grid item xs={12}>
                <h4>{t('survey.propertyDetails')}</h4>
                <TextField
                    name="comment"
                    data-testid="comment-input"
                    margin="normal"
                    onChange={onCommonChange}
                    label={t('survey.generalComment')}
                    value={survey.comment}
                    className={classes.textField}
                />
                {/*TODO: main image with comments*/}
                <h5>{t('general.address')}</h5>
            </Grid>
            <Grid item xs={12}>
                <AddressInput address={survey.address} addressChanged={onAddressChange} idBase={'surveyAddr-'} />
            </Grid>
            <Grid item xs={12}>
                <h5>{t('survey.propertyUsageAndCalssification')}</h5>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl className={classes.select} data-testid="survey-usage-form-control">
                    <InputLabel id="survey-usage">{t('survey.usage')}</InputLabel>
                    <Select
                        required
                        labelId="survey-usage"
                        id="survey-usage-select"
                        name="survey-usage-select"
                        data-testid="survey-usage-select"
                        value={survey.usage}
                        onChange={onCommonChange}>
                        <MenuItem value={1}>{t('survey.usageOffice')}</MenuItem>
                        <MenuItem value={2}>{t('survey.usageProductionIndustrial')}</MenuItem>
                        <MenuItem value={3}>{t('survey.usageHousing')}</MenuItem>
                        <MenuItem value={4}>{t('survey.usageWarehouseOrLogistics')}</MenuItem>
                        <MenuItem value={5}>{t('survey.usageShopping')}</MenuItem>
                        <MenuItem value={6}>{t('survey.usageSports')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl className={classes.select}>
                    <InputLabel id="survey-classification">{t('survey.classification')}</InputLabel>
                    <Select
                        required
                        labelId="survey-classification"
                        id="survey-classification-select"
                        data-testid="survey-classification-select"
                        value={survey.classification}
                        onChange={onCommonChange}>
                        <MenuItem value={1}>{t('survey.classificationOda1')}</MenuItem>
                        <MenuItem value={2}>{t('survey.classificationOda2')}</MenuItem>
                        <MenuItem value={3}>{t('survey.classificationOda3')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <h4>{t('survey.machineRooms', { pcs: survey.machineRooms.length })}</h4>
            </Grid>
            {survey.machineRooms.map((machineRoom, roomIndex) => (
                <Grid container key={`machineroom_${roomIndex}`}>
                    <Grid item xs={12}>
                        <h5>
                            {t('survey.machineRoom')}: {machineRoom.name}
                        </h5>
                        <TextField
                            name="machine-room-name"
                            data-testid={`machine-room-${roomIndex}-name`}
                            margin="normal"
                            onChange={(event) => onMachineChange(event, roomIndex, null)}
                            label={t('survey.machineRoom')}
                            value={machineRoom.name}
                            className={classes.textField}
                        />
                        <br />
                        {/*TODO: Machine rooms images with comment*/}
                    </Grid>

                    {machineRoom.machines.map((machine, machineIndex) => (
                        <React.Fragment key={`machineroom_${roomIndex}_machine_${machineIndex}`}>
                            <Grid item xs={12}>
                                <h5>
                                    {t('survey.machine')}: {machine.name}
                                </h5>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="machine-name"
                                    data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-name`}
                                    margin="normal"
                                    onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}
                                    label={t('survey.machine')}
                                    value={machine.name}
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="air-volume"
                                    data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-airvolume`}
                                    margin="normal"
                                    onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}
                                    label={t('location.airVolume')}
                                    value={machine.airVolume}
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.select}>
                                    <InputLabel id="machine-filter-class">{t('survey.filterClass')}</InputLabel>
                                    <Select
                                        required
                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-filter-class-select`}
                                        labelId="machine-filter-class"
                                        id="machine-filter-class"
                                        name="machine-filter-class"
                                        value={machine.filterClass}
                                        onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}>
                                        <MenuItem value={1}>{'ePM1'}</MenuItem>
                                        <MenuItem value={2}>{'ePM2,5'}</MenuItem>
                                        <MenuItem value={3}>{'ePM10'}</MenuItem>
                                        <MenuItem value={4}>{'Coarse'}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="filter-efficiency"
                                    data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-efficienty`}
                                    margin="normal"
                                    type="number"
                                    onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}
                                    label={t('survey.filterEfficiency')}
                                    value={machine.filterEfficiency}
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.select}>
                                    <InputLabel id="machine-filter-type">{t('survey.filterType')}</InputLabel>
                                    <Select
                                        required
                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-filter-type-select`}
                                        labelId="machine-filter-type"
                                        id="machine-filter-type"
                                        name="machine-filter-type"
                                        value={machine.filterType}
                                        onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}>
                                        <MenuItem value={1}>{t('survey.filterTypeBag')}</MenuItem>
                                        <MenuItem value={2}>{t('survey.filterTypeCartridge')}</MenuItem>
                                        <MenuItem value={3}>{t('survey.filterTypeOther')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.select}>
                                    <InputLabel id="machine-heat-recovery-mode">
                                        {t('survey.heatRecoveryModel')}
                                    </InputLabel>
                                    <Select
                                        required
                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-heat-recovery-select`}
                                        labelId="machine-heat-recovery-mode"
                                        id="machine-heat-recovery-mode"
                                        name="machine-heat-recovery-mode"
                                        value={machine.heatRecoveryModel}
                                        onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}>
                                        <MenuItem value={1}>{t('survey.heatRecoveryRotating')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="machine-service-area"
                                    data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-service-area`}
                                    margin="normal"
                                    onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}
                                    label={t('location.serviceArea')}
                                    value={machine.serviceArea}
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h5>{t('survey.usedFilterDimensions')}</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                {t('survey.height')}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {t('survey.width')}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {t('survey.maxDepth')}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {t('survey.quantity')}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {machine.filters.length === 0 && (
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <em>{t('survey.noMachines')}</em>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid container>
                                        {machine.filters.map((filter, filterIndex) => (
                                            <React.Fragment
                                                key={`machineroom_${roomIndex}_machine_${machineIndex}_filter_${filterIndex}`}>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        name="filter-height"
                                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-filter-${filterIndex}-height`}
                                                        margin="normal"
                                                        type="number"
                                                        onChange={(event) =>
                                                            onFilterChange(event, roomIndex, machineIndex, filterIndex)
                                                        }
                                                        value={filter.dimensionHeight}
                                                        className={classes.textField}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        name="filter-width"
                                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-filter-${filterIndex}-width`}
                                                        margin="normal"
                                                        type="number"
                                                        onChange={(event) =>
                                                            onFilterChange(event, roomIndex, machineIndex, filterIndex)
                                                        }
                                                        value={filter.dimensionWidth}
                                                        className={classes.textField}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        name="filter-maxdepth"
                                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-filter-${filterIndex}-maxdepth`}
                                                        margin="normal"
                                                        type="number"
                                                        onChange={(event) =>
                                                            onFilterChange(event, roomIndex, machineIndex, filterIndex)
                                                        }
                                                        value={filter.maxDepth}
                                                        className={classes.textField}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        name="filter-count"
                                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-filter-${filterIndex}-count`}
                                                        margin="normal"
                                                        type="number"
                                                        onChange={(event) =>
                                                            onFilterChange(event, roomIndex, machineIndex, filterIndex)
                                                        }
                                                        value={filter.count}
                                                        className={classes.textField}
                                                    />
                                                </Grid>
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ))}

                    <Grid item xs={12}>
                        {/*<Button
                            id="btn-new-machine"
                            variant="contained"
                            color="secondary"
                            name="btn-new-machiner"
                            onClick={null}>
                            TODO: new machine
                        </Button>*/}
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12}>
                {/*<Button
                    id="btn-new-machine"
                    variant="contained"
                    color="secondary"
                    name="btn-new-machiner"
                    onClick={null}>
                    TODO: new machine room
                </Button>*/}
            </Grid>
            <Footer>
                <Grid item>
                    <SaveButton saving={saving} disabled={saving} onSubmit={onSave} />
                </Grid>
            </Footer>
            <FooterPadding />
        </Grid>
    );
}
