import Ajax from './ajax';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { LOGGED_OFF } from './authentication';

export const SURVEY_SAVED = 'surveydata/SURVEYSAVED';

const initialState = {
    surveys: null,
    isFetchingSurveyData: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SURVEY_SAVED:
            return {
                ...state,
                surveys: null,
            };
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const getOpenSurveysForUserPortfolio = () => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get('api/survey');
            return resp.data;
        } catch (err) {
            return null;
        }
    };
};

export const getSurvey = (surveyId) => {
    return async (dispatch) => {
        try {
            console.log(`api/survey/${surveyId}`);
            const resp = await Ajax.get(`api/survey/${surveyId}`);
            return resp.data;
        } catch (err) {
            console.log(err);
        }
    };
};

export const getSurveyPdf = (surveyId, fileName = 'document.pdf') => {
    return async (dispatch) => {
        try {
            const response = await Ajax.get('api/survey/pdf/' + surveyId, {
                responseType: 'blob',
                acceptHeader: 'application/pdf',
            });

            if (response.status === 200) {
                var blob = new Blob([response.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();
                toast.info(i18next.t('createPdf.createPdfSuccess'), { autoClose: 1500, hideProgressBar: true });
                URL.revokeObjectURL(url);
            } else {
                toast.info(i18next.t('createPdf.createPdfFail'), { autoClose: 5000, hideProgressBar: true });
            }
        } catch (err) {
            console.log(err);
            toast.info(i18next.t('createPdf.createPdfFail'), { autoClose: 5000, hideProgressBar: true });
        }
    };
};

export const getImageUrl = (imageId) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get('api/survey/image/' + imageId);
            return resp.data;
        } catch (err) {
            return null;
        }
    };
};

export const saveSurvey = (survey) => {
    return async (dispatch) => {
        try {
            const response = await Ajax.put('api/survey/' + survey.id, survey);
            if (response.status === 204) {
                toast.info(i18next.t('survey.savingSuccess'), { autoClose: 1500, hideProgressBar: true });
                dispatch({ type: SURVEY_SAVED });
                return true;
            } else {
                return false;
            }
        } catch (err) {
            toast.info(i18next.t('survey.savingFail'), { autoClose: 5000, hideProgressBar: true });
            return false;
        }
    };
};
