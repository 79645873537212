import User from '../domain/user';

export const rbacmenuitems = {
    Locations: 'Locations',
    Customers: 'Customers',
    Home: 'Home',
    ShoppingCart: 'ShoppingCart',
    UserProfile: 'UserProfile',
    Logout: 'Logout',
    Reports: 'Reports',
    Products: 'Products',
    Feedback: 'Feedback',
    Admin: 'Admin',
    Portfolio: 'Portfolio',
    Production: 'Production',
    Invoices: 'Invoices',
    OrderHistory: 'OrderHistory',
    ShowcaseMode: 'showcaseMode',
};

export const rbacactions = {
    CreateInvoiceFromOrder: 'InvoiceFromOrder',
    MarkOrderDelivered: 'MarkOrderDelivered',
    CreateNewProduct: 'CreateNewProduct',
    LoadProductList: 'LoadProductList',
    ModifyProduct: 'ModifyProduct',
    AdjustContractPricing: 'AdjustContractPricing',
    MassUpdateProducts: 'MassUpdateProducts',
    ChangeOrderPortfolio: 'ChangeOrderPortfolio',
};

export const rbacrules = {
    [User.RoleUser()]: {
        paths: [
            '/locations/edit/:locationId',
            '/customers/edit/:customerId',
            '/locations/print/:locationId',
            '/customers/:customerId',
            '/locations/:locationId',
            '/order/:orderId',
            '/ordernumber/:orderNumber',
            '/ordernumber/edit/:orderNumber',
            '/home/',
            '/home',
            '/',
            '/locations',
            '/basket',
            '/customers',
            '/logout',
            '/feedback',
            '/reports',
            '/user',
            '/products',
            '/portfolio',
            '/search/:term',
            '/debug',
            '/orderhistory',
            '/locations/:locationId/energy',
            '/surveys',
            '/survey/edit/:surveyId',
        ],
        menuitems: [
            rbacmenuitems.Locations,
            rbacmenuitems.Customers,
            rbacmenuitems.Home,
            rbacmenuitems.ShoppingCart,
            rbacmenuitems.UserProfile,
            rbacmenuitems.Logout,
            rbacmenuitems.Reports,
            rbacmenuitems.Products,
            rbacmenuitems.Feedback,
            rbacmenuitems.Portfolio,
            rbacmenuitems.OrderHistory,
            rbacmenuitems.ShowcaseMode,
        ],
        actions: [],
    },
    [User.RoleCustomerUser()]: {
        paths: [
            '/locations/:locationId',
            '/order/:orderId',
            '/ordernumber/:orderNumber',
            '/home/',
            '/home',
            '/',
            '/locations',
            '/basket',
            '/logout',
            '/feedback',
            '/reports',
            '/user',
            '/orderhistory',
        ],
        menuitems: [
            rbacmenuitems.Locations,
            rbacmenuitems.Home,
            rbacmenuitems.ShoppingCart,
            rbacmenuitems.UserProfile,
            rbacmenuitems.Logout,
            rbacmenuitems.Reports,
            rbacmenuitems.Feedback,
            rbacmenuitems.OrderHistory,
        ],
        actions: [],
    },
    [User.RoleAllowPricing()]: {
        paths: [],
        menuitems: [],
    },
    [User.RoleProduction()]: {
        paths: ['/home/', '/user', '/home', '/production', '/feedback', '/logout'],
        menuitems: [
            rbacmenuitems.Home,
            rbacmenuitems.Production,
            rbacmenuitems.UserProfile,
            rbacmenuitems.Logout,
            rbacmenuitems.Feedback,
        ],
        actions: [rbacactions.MarkOrderDelivered],
    },
    [User.RoleInvoicing()]: {
        paths: [
            '/home/',
            '/home',
            '/user',
            '/invoices',
            '/invoices/edit/:invoiceid',
            '/feedback',
            '/logout',
            '/production',
        ],
        menuitems: [
            rbacmenuitems.Home,
            rbacmenuitems.Invoices,
            rbacmenuitems.UserProfile,
            rbacmenuitems.Logout,
            rbacmenuitems.Feedback,
            rbacmenuitems.Production,
        ],
        actions: [rbacactions.CreateInvoiceFromOrder],
    },
    [User.RoleAdmin()]: {
        paths: [
            '/admin',
            '/admin/portfolio/:portfolioId',
            '/admin/portfolio/edit/:portfolioId',
            '/admin/user/edit/:userId',
            '/admin/user/new',
            '/product/edit/:productId',
            '/product/bundleedit/:bundleId',
        ],
        menuitems: [rbacmenuitems.Admin],
        actions: [
            rbacactions.CreateNewProduct,
            rbacactions.ModifyProduct,
            rbacactions.LoadProductList,
            rbacactions.AdjustContractPricing,
            rbacactions.MassUpdateProducts,
            rbacactions.ChangeOrderPortfolio,
        ],
    },
    [User.RoleReporting()]: {
        paths: ['/reports'],
        menuitems: [rbacmenuitems.Reports],
        actions: [],
    },
    [User.RoleProductEditor()]: {
        paths: ['/product/edit/:productId', '/product/bundleedit/:bundleId'],
        menuitems: [],
        actions: [
            rbacactions.CreateNewProduct,
            rbacactions.ModifyProduct,
            rbacactions.LoadProductList,
            rbacactions.AdjustContractPricing,
        ],
    },
};
